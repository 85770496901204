import { makeStyles, shorthands } from '@fluentui/react-components';
import { ArrowRight20Regular, ChevronDown24Regular, ChevronUp24Regular } from '@fluentui/react-icons';
import React from 'react';

const useClasses = makeStyles({
    gridContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        '@media (max-width: 850px)': {
            gridTemplateColumns: 'repeat(2, 1fr)',
        }
    },
    smallBlockContainer: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#F8F8F8',
        ...shorthands.margin('5px'),
        ...shorthands.borderRadius('10px'),
        ...shorthands.padding('15px')
    },
    smallBlockCategoryText: {
        color: '#004068',
        fontWeight: 'bold',
        fontSize: '16px'
    },
    title: {
        display: 'flex',
        textAlign: 'center'
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        // alignItems: 'center',
        // justifyContent: 'center'   
    },
    button: {
        backgroundColor: 'white',
        cursor: 'auto',
        color: '#004068',
        fontWeight: '500',
        whiteSpace: 'normal',
        textAlign: 'left',
        ...shorthands.borderRadius('7px'),
        ...shorthands.padding('5px', '10px', '5px', '10px'),
        ...shorthands.margin('10px', 0, 0, 0),
        width: 'fit-content',
        fontSize: '15px',
        '&:hover': {
            backgroundColor: '#004068',
            cursor: 'pointer',
            color: '#FFFFFF',
            fontWeight: '700'
        }
    },
    columnContainer: {
        ...shorthands.padding('20px', 0, 0, 0),

    },
    largeBlockContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '30px',
    },
    largeBlockCategoryText: {
        ...shorthands.padding(0, 0, '10px', 0),
        fontWeight: 'bold',
        fontSize: '18px',
        color: '#030917'
    },
    followUp: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
});

interface ToolTipBlockProps {
    category: string;
    suggestions: Record<string, Record<string, string | Record<string, string | string[]>>>;
    setValue: React.Dispatch<React.SetStateAction<string>>;
}

export const ToolTipBlock: React.FC<ToolTipBlockProps> = ({ category, suggestions, setValue }) => {
    const classes = useClasses();

    const initialCollapsedState: Record<string, boolean> = {};
    Object.keys(suggestions).forEach(category => {
        initialCollapsedState[category] = true;
    });

    const [collapsed, setCollapsed] = React.useState<Record<string, boolean>>(initialCollapsedState); 
    const [hoveredLargeBlock, setHoveredLargeBlock] = React.useState<string | null>(null); // Tracks hovered large buttons to see further sub prompts

    const handleOnClick = (suggestion: string) => {
        setValue(suggestion);
        document.body.click();
    };

    const handleMouseEnter = (largeBlock: string) => {
        setHoveredLargeBlock(largeBlock);
    };

    const handleMouseLeave = () => {
        setHoveredLargeBlock(null);
    };

    return (
        category === "Small blocks" ? (
            <div className={classes.gridContainer}>
                {Object.keys(suggestions).map((category, index) => (
                    <div className={classes.smallBlockContainer} key={index}>
                        <span className={classes.smallBlockCategoryText}>{category}</span>
                        <div className={classes.wrapper}>
                            {Object.keys(suggestions[category]).map((smallBlock, index) => (
                                <button
                                    className={classes.button}
                                    key={index}
                                    style={{ transition: 'background-color 0.3s ease', border: '2px solid #CEDFE0' }}
                                    onClick={() => { handleOnClick(suggestions[category][smallBlock] as string) }}
                                >
                                    {smallBlock}
                                </button>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        ) : (
            <div className={classes.columnContainer}>
                {Object.keys(suggestions).map((category, index) => (
                    <div className={classes.largeBlockContainer} key={index}>
                        <div onClick={() => { setCollapsed(prevState => ({ ...prevState, [category]: !prevState[category] })) }} style={{ display: 'flex', alignItems: 'center' }}>  
                            <span className={classes.largeBlockCategoryText}>{category}</span>
                            {collapsed[category] ?
                                (<span style={{ marginLeft: 'auto' }}> <ChevronDown24Regular style={{ width: '15px', height: '25px' }}/>  </span>)
                                :
                                (<span style={{ marginLeft: 'auto' }}> <ChevronUp24Regular style={{ width: '15px', height: '25px' }}/> </span>)
                            }
                        </div>
                        {!collapsed[category] && (
                            <div>
                                {Object.entries(suggestions[category].Options).map(([largeBlock, values], index) => (
                                    <div key={index}
                                        onMouseEnter={() => { handleMouseEnter(largeBlock) }}
                                        onMouseLeave={handleMouseLeave}>

                                        <button className={classes.button}
                                            style={{ transition: 'background-color 0.3s ease', border: '2px solid #CEDFE0' }}
                                            onClick={() => { handleOnClick(largeBlock) }}
                                        >
                                            {largeBlock}
                                        </button>
                                        
                                        {hoveredLargeBlock === largeBlock && Array.isArray(values) && values.length > 0 && (  // rendering ;ogic to show sub prompt buttons based on hover state
                                            <div>
                                                {values.map((arrayValue, arrayIndex) => (
                                                    <div key={arrayIndex} className={classes.followUp}>
                                                        <ArrowRight20Regular style={{ paddingRight: '10px' }} />
                                                        <button
                                                            className={classes.button}
                                                            style={{ transition: 'background-color 0.3s ease', border: '2px solid #CEDFE0' }}
                                                            onClick={() => { handleOnClick(arrayValue) }}
                                                        >
                                                            {arrayValue}
                                                        </button>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        )}    
                    </div>              
                ))}
            </div>
        )
    );
};
